import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CONSTANTS } from "../core/constants/constants";
import { AppService } from "../services/app/app.service";
import { AppService as appSer } from "../../app/app.service";
import { AppService as appS } from "../app.service";
import { UTILITIES } from "../core/utility/utilities";
import { NgTinyUrlService } from "ng-tiny-url";
import { TemplateService } from "../services";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  @Input() menuName: any;
  search: any = localStorage.getItem("email");
  isSearch = false;
  searchData: any = "";
  lifeStyle: any;
  dietPlan = [
    { id: "fiteloWaterRetention", name: "Water retention" },
    { id: "fiteloWeightLoss", name: "High Protein fiber" },
    { id: "weightLoss", name: "Weight Loss" },
    { id: "muscleGain_morning", name: "Muscle Gain Morning" },
    { id: "muscleGain_evening", name: "Muscle Gain Evening" },
    { id: "fatShredding_morning", name: "Fat Shredding Morning" },
    { id: "fatShredding_evening", name: "Fat Shredding Evening" },
    { id: "diabetes", name: "Diabetes" },
    { id: "pcos", name: "PCOS" },
    { id: "cholesterol", name: "Cholesterol" },
    { id: "hypertension", name: "Hypertension" },
  ];
  planChoosen: any;
  profileInititals = "";
  updateTargetCal: any;
  username = "";
  recommendedTargetCal: any;
  isHindi: boolean = true;
  isDietitiian: boolean =
    localStorage.getItem("companyKey") === null ? false : true;
  userIdInput = "";
  key = "";
  isOpenPopup = false;
  link = "";
  link1 = "";
  profileData: any = {};
  diseases = [];
  diseases1 = [];
  alergies = ["SF,SO", "ML", "F", "E", "N", "G"];
  mealPref;
  defaultData: any;
  activityName;
  community = [];
  isOpen = false;
  url = "";
  constructor(
    private router: Router,
    private appS: appSer,
    private appServe: AppService,
    private appservice: appS,
    private utilities: UTILITIES,
    private tinyUrl: NgTinyUrlService,
    private templateService: TemplateService,
    private activateRoute: ActivatedRoute
  ) {}
  openPopup() {
    this.isOpen = !this.isOpen;
  }
  gotoProfile() {
    this.router.navigate(["edt-d"]);
  }
  title: any;
  ngOnInit() {
    this.searchData = "";
    this.username = "";
    console.log("DieticianName >> ", localStorage.getItem("dietitianName"));

    if (
      localStorage
        .getItem("loginEmail")
        .toLowerCase()
        .includes("beatoapp.com".toLowerCase())
    ) {
      this.username = "beato";
    } else if (localStorage.getItem("dietitianName") !== null) {
      this.username = localStorage.getItem("dietitianName");
    } else {
      this.username = localStorage.getItem("loginEmail");
    }
    this.search = localStorage.getItem("email");
  }

  ngAfterViewInit() {
    if (localStorage.getItem("menu").length > 10) {
      this.menuName = JSON.parse(localStorage.getItem("menu"));
    } else {
      localStorage.setItem(
        "menu",
        `{
        "title":"Users Summary",
        "description":[{"name":"This section displays a summary of the latest 20 contacts","desc":[]},{"name":"It has actionable buttons for WhatsApp, scheduling calls, or viewing diet plan details.","desc":[]}],
        "menu":"Home",
        "subMenu":"",
        "activeIndex":0   
    }`
      );
      this.menuName = JSON.parse(localStorage.getItem("menu"));
    }
  }

  additionPreferences: any;
  searchDetails: any;
  additionalPref(additionalPref) {
    this.additionPreferences = additionalPref;
  }

  fetchProfile() {
    console.log("Inside Navbar:: >> ", this.search);
    if (!this.search?.trim()) {
      return;
    }
    CONSTANTS.email = this.search;
    localStorage.setItem("email", this.search);
    this.isSearch = true;
    this.appS.searchProfile(this.search).then((profileData: any) => {
      this.searchData = profileData["_id"];
      console.log("profileData:-", profileData);
      this.lifeStyle = profileData?.lifeStyle;
      this.additionalPref(profileData["additionalPref"]);
      const data = profileData["profile"];
      if (data) {
        this.searchDetails = JSON.parse(JSON.stringify(profileData));
        localStorage.setItem("femail", "Yes");
        console.log("this.searchDetails", this.searchDetails);

        const planC = this.dietPlan.filter((item) => {
          return item.id === this.searchDetails?.additionalPref?.planChosen;
        });
        this.planChoosen = planC[0]?.name;
        this.updateTargetCal = this.searchDetails?.lifeStyle?.calories;
        this.recommendedTargetCal = this.searchDetails?.lifeStyle?.calories;
        const dd = JSON.parse(JSON.stringify(profileData));
        this.profileInititals = dd?.profile.name;
        // dd?.profile.name?.replace("  "," ")?.split(' ')[0][0].toUpperCase() +
        // (dd?.profile?.name?.replace("  "," ")?.split(' ')?.length>=2?dd?.profile?.name?.replace("  "," ")?.split(' ')[1][0].toUpperCase():dd?.profile?.name?.replace("  "," ")?.split(' ')[0][1].toUpperCase());

        this.isHindi =
          profileData["profile"]?.languagePref == undefined ? false : true;
        // this.search ='';
        this.appServe.getUserToken(this.search).subscribe(
          (response) => {
            console.log(response);
          },
          (error) => {
            console.log("User token ", error.error.text);
            localStorage.setItem("personal_token", error.error.text);
            console.log("Category Error", error);
          }
        );
        this.getProfile();
        this.getToken1();
      } else {
        localStorage.setItem("femail", "No");
        this.searchDetails = undefined;
      }
      this.router.navigate(["/preference-diet"]).then(() => {
        window.location.reload();
      });

      this.appS.getBeatoData(this.search).then(
        (res) => {
          console.log("response", res);
        },
        (err) => {
          console.log("error", err);
        }
      );
    });
  }

  closePopup() {
    this.userExisted = false;
    this.apiHitted = false;
    this.isOpenPopup = false;
  }
  clearMessage() {
    this.link = "";
    this.link1 = "";
    this.key = "";
    this.userIdInput = "";
    this.userExisted = false;
    this.apiHitted = false;
  }
  getTinyUrl(url) {
    this.tinyUrl.shorten(url).subscribe(
      (res) => {
        this.link = res;
        this.link1 = res;
        this.utilities.hideLoader();
      },
      (err) => {
        this.utilities.hideLoader();
        this.utilities.presentAlert("Something went wrong. Please try again!");
        console.log("error", err);
      }
    );
  }

  userExisted: boolean = false;
  apiHitted: boolean = false;
  isRegisteredUser() {
    let clientIdSystem = "";
    if (localStorage.getItem("loginEmail") === "orthocure") {
      this.key = "orthocure2024";
      clientIdSystem = "orthocure";
    } else if (localStorage.getItem("loginEmail") === "fitelo") {
      this.key = "FITELO2023";
      clientIdSystem = "fitelo";
    } else if (localStorage.getItem("companyKey") !== null) {
      this.key = localStorage.getItem("companyKey");
      clientIdSystem = localStorage.getItem("urlClientId");
    }
    if (this.key === "" || this.userIdInput === "") {
      this.utilities.presentAlert("Key and Profile id is mandatory!");
      return;
    }
    this.utilities.showLoading();
    console.log(this.userIdInput);
    const payload = {
      profile: {
        email: this.userIdInput,
      },
    };
    this.appS.getTokenExternal(this.key, this.userIdInput).subscribe((res1) => {
      // User is registered
      if (res1["code"] === "0000") {
        // const lnk = `https://testonboarding.smartdietplanner.com/read?token=${res1["accessToken"]}&clientId=${clientIdSystem}&type=1`;
        const lnk = `https://onboarding.smartdietplanner.com/read?token=${res1["accessToken"]}&clientId=${clientIdSystem}&type=1`;

        this.getTinyUrl(lnk);
        this.apiHitted = true;
        this.userExisted = true;
      } else {
        // User not registered
        this.appS.externalRegistration(this.key, payload).subscribe(
          (res) => {
            if (res["code"] !== "0000") {
              this.utilities.hideLoader();
              this.utilities.presentAlert(res["message"]);
            } else {
              //  const lnk = `https://testonboarding.smartdietplanner.com/read?token=${res["access_token"]}&clientId=${clientIdSystem}&type=1`;
              const lnk = `https://onboarding.smartdietplanner.com/read?token=${res["access_token"]}&clientId=${clientIdSystem}&type=1`;

              this.getTinyUrl(lnk);
              this.apiHitted = true;
              this.userExisted = false;
            }
            if (localStorage.getItem("dietitianId") != null) {
              // dietitianId, actionId, userId,  actionName,  companyId
              this.templateService
                .handleDietitianAction(
                  localStorage.getItem("dietitianId"),
                  "668d74731073b512dd2b9850",
                  this.userIdInput,
                  "Add New User",
                  localStorage.getItem("companyId")
                )
                .subscribe((dRespondata) => {
                  console.log("success", res["access_token"]);
                });
            }
          },
          (err) => {
            this.utilities.hideLoader();
            console.log("error", err);
          }
        );
      }
    });

    // this.isOpenPopup = false;
  }
  copyMessage(val: string) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this.link = "copied";
  }

  // fetchProfile() {
  //   console.log("Inside Navbar:: >> ", this.search);
  //   this.searchEvent.emit(this.search);
  // }

  editFxnClick() {
    this.router.navigate(["edt-d"]);
  }

  getProfile() {
    this.profileData = [];
    this.appservice.getProfile().then((res) => {
      this.profileData = res;

      if (this.profileData?.profile?.subCategory === "weightloss") {
        this.profileData.profile.subCategory = "Weight Loss";
      }
      if (this.profileData?.profile?.subCategory === "weightmaintenance") {
        this.profileData.profile.subCategory = "Weight Maintenance";
      }
      if (this.profileData?.profile?.subCategory === "musclebuilding") {
        this.profileData.profile.subCategory = "Muscle Building";
      }
      if (this.profileData?.profile?.subCategory === "leanbody") {
        this.profileData.profile.subCategory = "Lean Body";
      }

      let h: any =
        this.profileData?.demographic?.height?.unit === "in"
          ? this.profileData?.demographic?.height?.value / 12
          : this.profileData?.demographic?.height?.value;
      if (this.profileData?.demographic?.height?.unit === "in") {
        console.log(h);
        h = h.toString().split(".");
        console.log(h);
        const h1: any = (h[1] / 0.0833333).toString().split("0")[0];
        console.log(h1);
        this.profileData.demographic.height.value = `${h[0]}' ${h1}"`;
      } else {
        this.profileData.demographic.height.value =
          this.profileData.demographic.height.value + " cm";
      }
      console.log(this.profileData);
    });
  }

  getToken1() {
    if (!localStorage.getItem("email")) {
      this.utilities.presentAlert("Please enter email");
    } else {
      this.appServe.getUserToken(localStorage.getItem("email")).subscribe(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log("User token ", error.error.text);
          this.appS.getDefaultData(error.error.text).then((res) => {
            this.diseases = [];
            this.diseases1 = [];
            this.alergies = ["SF,SO", "ML", "F", "E", "N", "G"];
            this.mealPref;
            this.community = [];
            this.defaultData = res;
            this.activityName = this.defaultData.otherMaster.activities.filter(
              (item) => {
                return (
                  item.code ===
                  this.searchDetails?.lifeStyle?.activities["code"]
                );
              }
            );

            console.log("Activity Response:: ", this.defaultData);

            for (
              let index = 0;
              index < this.searchDetails?.lifeStyle?.diseases.length;
              index++
            ) {
              for (
                let j = 0;
                j < this.defaultData?.otherMaster?.diseases.length;
                j++
              ) {
                if (
                  this.defaultData?.otherMaster?.diseases[j].code ===
                  this.searchDetails?.lifeStyle?.diseases[index]
                ) {
                  if (
                    this.alergies.includes(
                      this.defaultData?.otherMaster?.diseases[j].code
                    )
                  ) {
                    this.diseases1.push(
                      this.defaultData?.otherMaster?.diseases[j].value
                    );
                  } else {
                    this.diseases.push(
                      this.defaultData?.otherMaster?.diseases[j].value
                    );
                  }
                }
              }
            }
            this.mealPref = this.defaultData?.otherMaster?.foodPref?.filter(
              (item) => {
                return item.code === this.searchDetails?.lifeStyle?.foodType;
              }
            );
            for (
              let index = 0;
              index < this.searchDetails?.lifeStyle?.communities.length;
              index++
            ) {
              for (
                let j = 0;
                j < this.defaultData?.otherMaster?.community.length;
                j++
              ) {
                if (
                  this.defaultData?.otherMaster?.community[j].code ===
                  this.searchDetails?.lifeStyle?.communities[index]
                ) {
                  this.community.push(
                    this.defaultData?.otherMaster?.community[j].value
                  );
                }
              }
            }
          });

          this.appS.getOnePlan1(error.error.text).then((res) => {
            console.log("getOnePlan", res);
          });
          console.log("Category Error", error);
        }
      );
    }
  }

  registerUser() {
    this.isOpenPopup = true;
    this.link = "";
    this.link1 = "";
    this.key = "";
    this.userIdInput = "";
  }

  editProfile() {
    localStorage.setItem("email", this.userIdInput);
    localStorage.setItem("femail", "Yes");
    localStorage.setItem("activeNum", "1");
    localStorage.setItem(
      "menu",
      `{
        "title":"View/Edit Profile",
        "description":[{"name":"This page allows you to view and update user details.","desc":[]}],
        "menu":"Profile",
        "subMenu":"",
        "activeIndex":1          
    }`
    );
    this.router.navigate(["edit-profile"]).then(() => {
      window.location.reload();
    });
  }

  logout() {
    localStorage.clear();
    localStorage.setItem("email", "");
    localStorage.setItem("femail", "No");
    this.search = "";
    this.router.navigate(["login"]);
    this.searchData = "";
    this.username = "";
  }
}
