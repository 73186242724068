import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Form } from "@angular/forms";
import { APIS } from "src/app/shared/constants";

@Injectable({
  providedIn: "root",
})
export class TemplateService {
  constructor(private httpClient: HttpClient) {}

  getTemplate(userId: string, companyId: string) {
    return this.httpClient.get(
      APIS.nodeBaseUrl +
        `template/getAllTemplates?userId=${userId}&companyId=${companyId}`
    );
  }

  toggleTemplate(templateId: string, userId: string) {
    return this.httpClient.put(
      APIS.nodeBaseUrl +
        `template/toggleByTempId?templateId=${templateId}&userId=${userId}`,
      {}
    );
  }

  deleteTemplate(templateId: string, userId: string) {
    return this.httpClient.delete(
      APIS.nodeBaseUrl + `template/delete?id=${templateId}&userId=${userId}`,
      {}
    );
  }

  addInstructions(
    userId: string,
    normalDay: string,
    cheatDay: string,
    detoxDay: string,
    createrId: string
  ) {
    return this.httpClient.post(APIS.nodeBaseUrl + `instructions/add`, {
      userId,
      normalDay,
      cheatDay,
      detoxDay,
      createrId,
    });
  }

  getInstructions(userId: string) {
    return this.httpClient.get(
      APIS.nodeBaseUrl + `instructions/getByUserId/${userId}`
    );
  }

  updateInstructions(
    id: string,
    userId: string,
    normalDay: string,
    cheatDay: string,
    detoxDay: string,
    createrId: string
  ) {
    return this.httpClient.post(
      APIS.nodeBaseUrl + `instructions/update/${id}`,
      { userId, normalDay, cheatDay, detoxDay, createrId }
    );
  }

  getQaByUser(id: string) {
    return this.httpClient.get(APIS.nodeBaseUrl + `qa/getByUserId/${id}`);
  }

  createQa(userId: string, createrId: string, content: any[]) {
    return this.httpClient.post(APIS.nodeBaseUrl + `qa/add`, {
      userId,
      createrId,
      content,
    });
  }

  updateQaByUser(
    id: string,
    userId: string,
    createrId: string,
    content: any[]
  ) {
    return this.httpClient.post(APIS.nodeBaseUrl + `qa/update/${id}`, {
      userId,
      createrId,
      content,
    });
  }

  loginByEmail(mail: string) {
    return this.httpClient.get(APIS.nodeBaseUrl + `tmsdp/verifyMail/${mail}`);
  }

  generateOtp(mail: string) {
    return this.httpClient.post(APIS.nodeBaseUrl + "tmsdp/generateOtp", {
      email: mail,
    });
  }

  verifyOtp(email: string, otp: string) {
    return this.httpClient.post(APIS.nodeBaseUrl + "tmsdp/verifyOtp", {
      email,
      otp,
    });
  }

  createDietitian(body: object) {
    return this.httpClient.post(
      APIS.nodeBaseUrl + "dietitian/createDietitian",
      body
    );
  }

  getDietitianRecord(mail: string) {
    return this.httpClient.get(
       APIS.nodeBaseUrl + `dietitian/getDietitianRecord?mail=${mail}`
      // 'http://localhost:8080/api/' + `dietitian/getDietitianRecord?mail=${mail}`
    );
  }

  updateDietitianRecord(payload: FormData) {
    console.log("Payload --> ", payload);
    const headers = new HttpHeaders({'Content-Type': 'multipart/form-data; boundary=' + payload});
    // "Content-Type": "multipart/form-data",
    // multipart/form-data; boundary=----WebKitFormBoundary5TPWrTQeBZ6yyB3e

    return this.httpClient.post(
      // APIS.nodeBaseUrl + `dietitian/updateDietitianRecord`,
      'http://localhost:8080/api/' + `dietitian/updateDietitianRecord`, payload, //{ headers }
    );
  }

  handleDietitianAction(
    dietitianId: string,
    actionId: string,
    userId: string,
    actionName: string,
    companyId: string
  ) {
    return this.httpClient.post(
      APIS.nodeBaseUrl + `dietitian/newDietitianAction`,
      {
        dietitianId,
        actionId,
        userId,
        actionName,
        companyId,
        dietitianName: localStorage.getItem("dietitianName"),
      }
    );
  }

  getAllDietitianActionSummary(dietitianId: string) {
    return this.httpClient.get(
      APIS.nodeBaseUrl + `dietitian/dietitianActions?dietitianId=${dietitianId}`
    );
  }

  getAllDietitians(companyId: string) {
    return this.httpClient.get(
      APIS.nodeBaseUrl +
        `dietitian/getAllDietitianRecord?companyId=${companyId}`
    );
  }

  createAssignedDietitian(payload: Object) {
    return this.httpClient.post(
      APIS.nodeBaseUrl + `dietitian/createAssignedDietitian`,
      payload
    );
  }

  updateAssignedDietitian(id: string, payload: Object) {
    return this.httpClient.put(
      APIS.nodeBaseUrl + `dietitian/updateAssignedDietitian?id=${id}`,
      payload
    );
  }

  getProfileLink(companyKey: string, userId: string) {
    return this.httpClient.post(APIS.nodeBaseUrl + `tmsdp/genLink`, {
      companyKey,
      userId,
    });
  }

  getSingleCompanyRecord() {
    return this.httpClient.get(
      APIS.nodeBaseUrl + `tmsdp/getCompany?companyId=yellowsquash`
    );
  }

  generatePdf(
    companyId: string,
    date: string,
    authToken: string,
    userId: string,
    dietitianName: string,
    dietitianEmail: string
  ) {
    const headers = new HttpHeaders({
      Accept: "application/pdf",
    });

    return this.httpClient.get(
      APIS.pythonBaseUrl +
        `gen_pdf?auth_token=${authToken}&date_range=7&date=${date}&company_id=${companyId}&user_id=${userId}&trigger_webhook=true&dietitian_name=${dietitianName}&dietitian_email=${dietitianEmail}`,
      {
        headers: headers,
        responseType: "blob",
      }
    );
  }

  addHealthParams(payload: Object) {
    return this.httpClient.post(
      APIS.nodeBaseUrl + `profile/addHealthParams`,
      payload
    );
  }

  getHealthParams(userId: string) {
    return this.httpClient.get(
      APIS.nodeBaseUrl + `profile/getHealthParamsByUserId?userId=${userId}`
    );
  }

  updateHealthParams(payload: Object) {
    return this.httpClient.put(
      APIS.nodeBaseUrl + `profile/updateHealthParams`,
      payload
    );
  }

  verifyDietPlanRepeat(mail: string) {
    return this.httpClient.get(
      APIS.nodeBaseUrl + `dietitian/isDietPlanRepeated?email=${mail}`
    );
  }

  setRepeatForFutureDaysFalse(email: string) {
    return this.httpClient.put(
      APIS.nodeBaseUrl + `dietitian/setRepeatForFutureDaysFalse`,
      { email }
    );
  }

  initiatePayment(email: string) {
    return this.httpClient.post(
      'http://localhost:8080/api/' + `payment/createOrder`,
      {
        "amount": 100,
        "amount_due": 100,
        "amount_paid": 0,
        "attempts": 0,
        "created_at": 1725646556,
        "currency": "INR",
        "entity": "order",
        "id": "order_OtyaZ0qlXStZ7o",
        "notes": [],
        "offer_id": null,
        "receipt": "recipt#1",
        "status": "created"
      }
    );
  }
}
