import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RouteCheckService } from "./services/route-check/route-check.service";
import { Observable } from "rxjs";
import menuSummery from './shared/constants/menu-summary.json';
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  isLogged: boolean = false;
  cURL$: Observable<string>;
  
  constructor(
    private router: Router,
    private routeCheckService: RouteCheckService
  ) {
    if (
      !localStorage.getItem("acess_token") &&
      localStorage.getItem("acess_token") == null
    ) {
      this.router.navigate(["./tabs/home"]);
    }
  }
  menuName: any;
  menuOptions(event) {
    debugger;
    localStorage.setItem("menu", "");
   const dataItem = menuSummery.filter(item=>{
      return item.activeIndex === event.item.activeNumber && item.title.toLowerCase() === event.title.toLowerCase();
    });
    
    localStorage.setItem("menu", JSON.stringify(dataItem[0]));
    console.log("ddddd:-",localStorage.getItem("menu"));
    this.menuName = dataItem[0];
  }
  ngOnInit(): void {
    this.isLogged = localStorage.getItem("acess_token") !== "";
    console.log(">> Id logged >> ", this.isLogged);
    this.cURL$ = this.routeCheckService.currentUrl$;
    this.routeCheckService.currentUrl$.subscribe((url) => {
      console.log("Current Route:", url);
      // // alert(url);
      if (url !== null && url.includes("authenticate")) {
        //   console.log(">>> ", url);
        //   // alert(url);
        this.isLogged = false;
        //   localStorage.setItem("acess_token", "eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJlbWFpbCI6IjkxLTc3MjY5MjI0MDUiLCJkZXZpY2VJZCI6IjIxMzIxMzIxIiwiaWF0IjoxNzIyMTQ5NjY2fQ.6g4qgKUJHevMl4d7DEyfElSgZOz-u0Sv_mfK7r1qQdUiQiS_ohlGwbrscGjV4gdTucvwUNbCpdrqfIZEcOTazw");
      }
      // // You can use this.currentRoute to manage the state
      else if (url === "/login" || url === "/first-page") {
        this.isLogged = false;
        localStorage.setItem("femail", "No");
      } else {
        this.isLogged = true;
      }
    });
    // alert(this.searchData);
  }

  getIsSearched(): boolean {
    return localStorage.getItem("femail") == "Yes";
  }
}
